<script>
import Vue from 'vue';
import BaseVue from '@frontend/Base.vue';
import Gen from '../helper/Gen';
Vue.component("Header", () => import ("@frontend/part/Header.vue"))

export default {
    extends: BaseVue,
    data() {
        return {
            testimoni: [],
            heroImage:{},
            karir: [],
            infoText:'',
            input:{},
            info:false,
            infoError:false,
            staticData:{},
            sliderData:[],
        }
    },
    async mounted() {
        await this.ready();
        this.$set(this.$root, 'page', this);
        Vue.component("Header",()=>import("@frontend/part/Header.vue"))
        if (typeof SEMICOLON != "undefined") SEMICOLON.slider.init()
        setTimeout(() => {
            SEMICOLON.documentOnReady.init()
            SEMICOLON.widget.carousel();
        }, 100)
        $(document).ready(() => {
            AOS.init()
        })
        this.apiGetTestimoni()
        this.apiGetKarir()
        this.apiGetHero()
        this.apiGetStaticContent()
        this.apiGetSlider()
    },
    methods: {
        apiGetTestimoni() {
            Gen.apirest('/testimoni', {}, (err, resp) => {
                if (err) console.log(err)
                this.testimoni = resp.data
                $('.owl-carousel').owlCarousel('destroy'); 
                setTimeout(() => { 
                    SEMICOLON.documentOnReady.init()
                    SEMICOLON.widget.carousel()
                }, 300)
            })
        },
        apiGetKarir() {
            Gen.apirest('/karir', {}, (err, resp) => {
                if (err) console.log(err)
                this.karir = resp.data
            })
        },
        apiGetHero(){
            Gen.apirest('/hero/5', {}, (err, resp)=>{
                if(err) console.log(err)
                this.heroImage=resp.data
                this.$root.topProgress.done()
            })
        },
        submitForm(e){
            if(e.btnLoading()) return;
            Gen.apirest("/applyform", this.input, (err,resp)=>{
                if(resp.success===500){
                    e.btnUnloading();
                    this.infoError=true
                    this.infoText=resp.message
                    setTimeout(()=>{
                        this.infoError = false
                    },5000)
                    return
                }
                e.btnUnloading();
                this.info=true
                this.infoText=resp.message
                setTimeout(()=>{
                    this.info = false
                    $("input[type=text], textarea").val("");
                    $('.close').click()
                },3000)
            }, "POST");
        },
        apply(v){
            this.input.saf_mc_id = v.mc_id
            $('input[name="saf_mc_id"]').val(v.mc_id)
        },
        apiGetStaticContent(){
            Gen.apirest('/static-career', {}, (err, resp)=>{
                if(err) console.log(err)
                this.staticData=resp.data
            })
        },
        apiGetSlider(){
            Gen.apirest('/slider-career', {}, (err, resp)=>{
                if(err) console.log(err)
                this.sliderData=resp.data
                $('.owl-carousel').owlCarousel('destroy'); 
                setTimeout(() => {
                    SEMICOLON.documentOnReady.init()
                    SEMICOLON.widget.carousel();
                }, 100)
            })
        },
    },
    watch: {}
};
</script>
<template>
<div Content>
<Header></Header>
<section id="slider" class="slider-element slider-parallax swiper_wrapper clearfix sliderActivities">
    <!-- slider-parallax-inner -->
    <div class="paralaxActivities">
        <div class="swiper-container swiper-parent">
            <div v-if="!isMobile" class="swiper-wrapper" :style="'background-image: url(\''+uploader(heroImage.mhi_image, '1349x350')+'\'); background-size: cover; background-position: top;'">
                <div class="container clearfix">
                    <div class="slider-caption go-mid dark">
                        <h3>{{heroImage.mhi_title}}</h3>
                        <p class="mt-1">{{heroImage.mhi_desc}}</p>
                    </div>
                </div>
                <div class="video-overlay" style="background-color: rgba(0,0,0,0.3);"></div>
            </div>
            <div v-else class="swiper-wrapper" :style="'background-image: url(\''+uploader(heroImage.mhi_image, '360x240')+'\'); background-size: cover; background-position: top;'">
                <div class="container clearfix">
                    <div class="slider-caption go-mid dark">
                        <h3>{{heroImage.mhi_title}}</h3>
                        <p class="mt-1">{{heroImage.mhi_desc}}</p>
                    </div>
                </div>
                <div class="video-overlay" style="background-color: rgba(0,0,0,0.3);"></div>
            </div>
        </div>
        <a href="javascript:;" data-scrollto="#content" data-offset="100" class="dark one-page-arrow"><i class="icon-angle-down infinite animated fadeInDown"></i></a>
    </div>
</section>
<section id="page-title" class="page-title-mini">
    <div class="container clearfix">
        <h1 class="d-none d-md-block">&nbsp;</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><router-link :to="{name:'index'}">Home</router-link></li>
            <li class="breadcrumb-item active" aria-current="page">Karir</li>
        </ol>
    </div>
</section>
<section id="content" class="overflow-none">
    <div class="content-wrap">
        <section class="section nobg nobottommargin notopmargin">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-6">
                        <div data-autoplay="2000" data-loop="true" data-margin="15" data-pagi="true"
                            data-items-xs="1" data-items-sm="1" data-items-md="1" data-items-lg="1"
                            data-items-xl="1"
                            class="owl-carousel products-carousel carousel-widget owl-loaded owl-drag">
                            <div class="prod_farm_wrap" v-for="(p,k) in sliderData">
                                <VImg v-if="!isMobile" :src="uploader(p.mcp_file, 555)" :alt="p.mcp_title" :title="p.mcp_title"></VImg>
                                <VImg v-else :src="uploader(p.mcp_file, 330)" :alt="p.mcp_title" :title="p.mcp_title"></VImg>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-5 mt-2">
                        <div class="heading-block">
                            <h3>Mengapa bergabung<br> bersama <span>Farmsco</span> </h3>
                        </div>
                        <div class="wrap_about_intro" v-html="staticData.msc_desc">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section testi notopmargin nobottommargin">
            <div class="container">
                <div class="row justify-content-center" v-if="testimoni.length">
                    <div class="col-12">
                        <div class="heading-block center">
                            <h3>Testimoni Karyawan</h3>
                        </div>
                    </div>
                    <div class="col-md-10">
                        <div data-autoplay="5000" data-loop="true" data-margin="15" data-pagi="true" data-items-xs="1" data-items-sm="1"
                            data-items-md="1" data-items-lg="1" data-items-xl="1"
                            class="owl-carousel products-carousel carousel-widget owl-loaded owl-drag">
                            <div class="value" v-for="(t,k) in testimoni">
                                <div class="val-img">
                                    <VImg :src="uploader(t.met_photo, '158x158')" :alt="t.met_fullname" :title="t.met_fullname"></VImg>
                                </div>
                                <div class="val-desc">
                                    <h4>{{t.met_testimoni}}</h4>
                                    <div class="name">
                                        <strong class="d-block">{{t.met_fullname}}</strong>
                                        <span>{{t.met_position}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section nobg nobottommargin notopmargin" v-if="karir.length">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="heading-block center">
                            <h3>Bergabung bersama kami</h3>
                        </div>
                    </div>
                    <div class="col-md-11 col-lg-10"  >
                        <div class="wrap_vacancy" data-toggle="collapse" v-for="(c,k) in karir" :data-target="'#collapseExample'+k"
                            aria-expanded="false" aria-controls="collapseExample">
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="ic_vacancy">
                                        <VImg :src="assets('fo_images','job.png')" /></VImg>
                                    </div>
                                </div>
                                <div class="col-md-9 text-center text-md-left">
                                    <h3>{{c.mc_title}}</h3>
                                    <p>{{c.mc_sort_desc}}</p>
                                    <p><span style="color:red;">{{c.mc_country+', '+c.mc_city}}</span></p>
                                </div>
                                <div class="col-md-1">
                                    <div class="ic_chevron">
                                        <i class="icon-chevron-down1"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="wrap_quality collapse" :id="'collapseExample'+k" >
                                        <div v-html="c.mc_desc"></div>
                                        <a @click="apply(c)" href="#detailProd" data-toggle="modal" data-target="#applyJob" class="btn btn-border_red">Apply Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- <section class="section nobottommargin notopmargin">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="wrap_join">
                            <p>Kirimkan CV/Portofolio dan Dokumen pendukung lainnya ke:</p>
                            <h3>recruitment@farmsco.co.id</h3>
                            <p>*Subjek email :<strong> “Nama pelamar - Posisi yang dilamar”</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
    </div>
</section>
<div class="modal fade" id="applyJob">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="wrap_comp_logo">
                <img :src="assets('fo_images','circle_logo.png')" alt="logo" />
            </div>
            <div class="modal-header">
                <h4 class="modal-title">Application Form</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <VForm @resp="submitForm" method="post" id="apply_form">
                    <div class="form-row">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="applyLinkedin">Profil LinkedIn <small>(Opsional)</small></label>
                                <input type="url" v-model="input.saf_linkedin" class="form-control form-control-sm" id="applyLinkedin" placeholder="https://linkedin.com/in/yourusername">
                                <input type="hidden" v-model="input.saf_mc_id" name="saf_mc_id">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="applyName">Nama<span class="required">*</span></label>
                                <input type="text" v-model="input.saf_fullname" required="" class="form-control form-control-sm" id="applyName">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="applyTel">No. Telepon<span class="required">*</span></label>
                                <input type="text" v-model="input.saf_phone" required="" maxlength="12" class="form-control form-control-sm" id="applyTel">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="applyEmail">Email<span class="required">*</span></label>
                                <input type="email" v-model="input.saf_email" required="" maxlength="50" minlength="10" class="form-control form-control-sm" id="applyEmail">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="applyAddress">Alamat<span class="required">*</span></label>
                                <textarea minlength="5" v-model="input.saf_address" required="" class="form-control form-control-sm" id="applyAddress"></textarea>
                            </div>
                        </div>
                        <div class="col-12">
                            <Uploader :label="'CV'" name="saf_file" type="resume" uploadType="" v-model="input.saf_file">
                            </Uploader>
                        </div>
                    </div>
                    <div class="col-12 mt-4 text-right">
                        <div class="info alert alert-success" v-if="info">{{infoText}}</div>
                        <div class="info alert alert-danger" v-if="infoError">
                            <ul style="margin-bottom:0px;">
                                <li v-for="e in infoText">{{e}}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 mt-4 text-right">
                        <button class="btn btn-primary btn-loading" href="#">{{ web.btn_apply }}</button>
                    </div>
                    </VForm>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</template>